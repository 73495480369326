/*---------------------
    Tab Styles  
-----------------------*/

.rn-default-tab {
    padding-bottom: 40px;
    // Tab Button 
    .tab-button {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        .react-tabs__tab {
            padding: 20px 25px;
            margin: 0 0 -15px 0;

            @media #{$lg-layout} {
                padding: 20px 20px;
            }

            @media #{$md-layout} {
                padding: 20px 20px;
            }

            @media #{$sm-layout} {
                padding: 20px 20px;
            }

            &.react-tabs__tab--selected {
                background: var(--color-lessdark);
            }
        }
        .rn-tab-button {
            button {
                padding: 0;
                margin: 0;
                background: transparent;
                border: 0 none;
                color: var(--color-white);
            }
        }
    }

    // Tab Content 
    .rn-tab-content {
        background: var(--color-lessdark);
        padding: 40px 40px 60px 40px;

        border-radius: 15px 15px 15px 15px;

        @media #{$sm-layout} {
            padding: 30px;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.style-two {
        .tab-button {
            margin-bottom: 30px;
            .react-tabs__tab {
                border-radius: var(--radius-small);
            }
        }
        .rn-tab-content {
            border-radius: var(--radius-small);
        }
    }

    ul {
        margin-bottom: -20px;
    }
}

.react-tabs__tab.react-tabs__tab--selected {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}